'use client'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useRouter } from 'next/navigation'
import { useCallback, useEffect } from 'react'

import { useSentry } from '@features/error/hooks/useSentry'

import { useLang } from '@hooks/useLang'
import { useOrganizationId } from '@hooks/useOrganizationId'

import { useUser } from '../../hooks/useUser'
import { pagesPath } from '../../utils/$path'

import { isAxiosError } from '@atnd/axios'
import { app } from '@atnd/firebase'
import { setFirebaseIdToken } from '@atnd/utils'
import { useAtomValue, useSetAtom } from 'jotai'
import type { FC, PropsWithChildren } from 'react'
import { authLoadingAtom, isCreatingUserAtom, useSnackbar } from 'shared/features'

export const PatientAuthGuard: FC<PropsWithChildren> = ({ children }) => {
	const lang = useLang()
	const router = useRouter()
	const { openSnackbar } = useSnackbar()
	const { pushErrorToSentry } = useSentry()

	const organizationId = useOrganizationId()
	const { login, logout, user } = useUser()

	// ローディング状態を管理する
	const setAuthLoading = useSetAtom(authLoadingAtom)

	const isCreatingUser = useAtomValue(isCreatingUserAtom)

	const setSuccess = useCallback(() => {
		setAuthLoading('success')
	}, [setAuthLoading])

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const auth = getAuth(app)
		const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
			const pathname = window.location.pathname ?? ''
			const homePath = pagesPath._lang(lang).organizations._organizationId(organizationId).user.$url().path

			// ユーザーが作成中の場合は、完了するまでログインを待つ
			if (isCreatingUser) return

			try {
				if (!firebaseUser) {
					// FirebaseUserが存在しない場合は初期化
					return await logout?.()
				}

				if (firebaseUser) {
					const idToken = await firebaseUser.getIdToken()
					setFirebaseIdToken(idToken)
					router.prefetch(homePath)

					if (!user) {
						// FirebaseUserが存在するかつ、Userが存在しない場合、Userを取得する
						await login?.(idToken)
					}

					// ログイン画面の場合はトップページにリダイレクト
					const isLoginPage = pathname.includes('/login')
					if (isLoginPage) {
						router.push(homePath)
					}
				}
			} catch (error) {
				console.info({ error })
				if (isAxiosError(error)) {
					/**
					 * サーバーの障害で一時的にユーザーが登録できない不具合が発生しました2023/09 ~ 10月頃
					 * DBにユーザーが存在しない場合、サーバー側でFirebaseAuthのユーザーを削除
					 * Responseで404が返ってくるので、その場合はアカウントの再登録を促す
					 */
					const isNotFound = error.response?.status === 404
					const notFoundMessage = `大変申し訳ございません。こちらのアカウントは新規作成時にエラーがあり、正常に登録されませんでした。\nご入力のメールアドレスとシステムの紐付けをリセット致しましたので、再度新規登録の上ご利用ください。\n\nこの度はご迷惑をおかけし誠に申し訳ございませんでした。`
					isNotFound && openSnackbar(notFoundMessage, 'error')

					isNotFound &&
						pushErrorToSentry({
							category: 'Authentication',
							error,
							message: notFoundMessage,
							payload: {
								firebaseUserId: firebaseUser?.uid,
							},
						})
				}
				setAuthLoading('error')
			} finally {
				setSuccess()
			}
		})

		return () => unsubscribe()
	}, [user, isCreatingUser])

	return <>{children}</>
}
