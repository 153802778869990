'use client'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { jaJP } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Provider } from 'jotai'

import type { FC, PropsWithChildren } from 'react'
import { SnackbarProvider } from 'shared/features'
import { theme } from 'ui/theme'
export const AppProviders: FC<PropsWithChildren> = ({ children }): JSX.Element => {
	return (
		<Provider>
			<CssBaseline />
			<ThemeProvider theme={theme}>
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					adapterLocale={jaJP}
					dateFormats={{ monthAndYear: 'YYYY年 MM月' }}
					localeText={{
						previousMonth: '前月を表示', // < のツールチップ
						nextMonth: '次月を表示', // > のツールチップ
					}}
				>
					<SnackbarProvider>{children}</SnackbarProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</Provider>
	)
}
