import { FirebaseError } from 'firebase/app'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'

import { useSentry } from '@features/error/hooks/useSentry'

import { type FirebaseUser, getLoginErrorMessage } from '@atnd/firebase'
import { Sentry } from '../utils/sentry'

import { type CreateReservationDto, api } from '@atnd/api-client'
import { useCallback } from 'react'
import {
	isCreatingUserAtom,
	loginAtom,
	logoutAtom,
	signInWithEmailAtom,
	updateUserReservationAtom,
	useSnackbar,
	userAtom,
} from 'shared/features'
import { useOrganizationId } from './useOrganizationId'

export const useUser = () => {
	const { openSnackbar } = useSnackbar()
	const organizationUrlAlias = useOrganizationId()
	const { pushErrorToSentry } = useSentry()

	const [user, setUser] = useAtom(userAtom)

	const createReservation = (payload: CreateReservationDto) => {
		return api.reservations.$post({ body: payload })
	}
	const updateReservation = useSetAtom(updateUserReservationAtom)

	const isCreatingUser = useAtomValue(isCreatingUserAtom)
	const signInWithEmailAndPassword = useSetAtom(signInWithEmailAtom)
	const login = useSetAtom(loginAtom)
	const logout = useSetAtom(logoutAtom)

	const handleLoginWithEmail = async (email: string, password: string): Promise<FirebaseUser | undefined> => {
		try {
			return signInWithEmailAndPassword({ email, password })
		} catch (error) {
			const errorMessage = getLoginErrorMessage(error)
			openSnackbar(errorMessage, 'error')

			if (error instanceof FirebaseError && error.code === 'auth/user-not-found') {
				pushErrorToSentry({
					message: errorMessage,
					error,
					category: 'Authentication',
				})
			}
		}
	}

	const handleLogin = useCallback(
		async (token: string) => {
			if (isCreatingUser) return

			try {
				await login({
					idToken: token,
					organizationUrlAlias,
				})
			} catch (error) {
				Sentry.addBreadcrumb({
					message: 'ユーザー取得エラー データベースにユーザーが存在しない(FirebaseAuth有)',
					category: 'User',
					timestamp: Date.now(),
				})
				Sentry.captureException(error)

				logout()

				throw error
			}
		},
		[logout, login, isCreatingUser, organizationUrlAlias],
	)

	const reserve = async (payload: CreateReservationDto) => {
		const newReservation = await createReservation(payload)

		setUser((prev) => (prev ? { ...prev, reservations: [newReservation, ...prev.reservations] } : undefined))

		return newReservation
	}

	const alreadyIntegrated = (hospitalId: string) => {
		return user?.hospitals?.some((hospital) => hospital.id === hospitalId) ?? false
	}

	/** NOTE: クリニックの連携
	 * クリニックの連携は、クリニックのスタッフが代理でユーザーの予約を行うために必要な機能です
	 * ユーザーの代理予約は、過去にそのクリニックで診療を受けたことがある場合、もしくはクリニックと連携済みの場合に行うことができます
	 * */
	const integrateHospital = async (hospitalId: string) => {
		if (alreadyIntegrated(hospitalId)) {
			return
		}

		try {
			const hospitalRelation = await api.user_hospital_relations.$post({
				body: { hospital_id: hospitalId },
			})

			setUser((prev) =>
				prev
					? {
							...prev,
							hospitals: [hospitalRelation.hospital, ...prev.hospitals],
						}
					: undefined,
			)
			openSnackbar('クリニックの連携が完了しました', 'success')
		} catch (error) {
			pushErrorToSentry({
				message: 'クリニック連携に失敗しました',
				payload: { hospitalId },
				category: 'User',
				error,
			})
			openSnackbar('クリニックの連携に失敗しました', 'error')
		}
	}

	const leave = async () => {
		if (!user?.id) return
		await api.users._id(user?.id).$delete()
		logout()
	}

	return {
		user,
		leave,
		reserve,
		updateReservation,
		login: handleLogin,
		logout,
		loginWithEmail: handleLoginWithEmail,
		integrateHospital,
		alreadyIntegrated,
	}
}
