import { isAxiosError } from '@atnd/axios'
import { getCurrentFirebaseUser } from '@atnd/firebase'
import * as Sentry from '@sentry/nextjs'
import { useCallback } from 'react'

type Category = 'Authentication' | 'Reservation' | 'ChiefComplaint' | 'Contact' | 'User'

export const useSentry = () => {
	const pushErrorToSentry = useCallback(
		({
			category,
			error,
			message,
			payload,
		}: {
			category?: Category
			payload?: Record<string, unknown>
			error: unknown
			message?: string
		}) => {
			const firebaseUser = getCurrentFirebaseUser()

			Sentry.addBreadcrumb({
				category,
				data: {
					payload: {
						...payload,
						firebaseUserId: firebaseUser?.uid,
					},
					status: isAxiosError(error) ? error.response?.status : undefined,
				},
				message,
			})
			Sentry.captureException(error)
		},
		[],
	)

	return { pushErrorToSentry }
}
