import(/* webpackMode: "eager" */ "/vercel/path0/apps/patient_frontend/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["PatientAuthGuard"] */ "/vercel/path0/apps/patient_frontend/src/components/layout/PatientAuthGuard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/vercel/path0/apps/patient_frontend/src/providers/AppProviders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.25_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3._2vpwwb7wnf47bmax62htd7ogpm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.25_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3._2vpwwb7wnf47bmax62htd7ogpm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@next+third-parties@15.0.3_next@14.2.25_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3._2vpwwb7wnf47bmax62htd7ogpm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.25_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@_sptkx6lvamfqyk473iuhptnvmi/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/new-ui/dist/index.css");
